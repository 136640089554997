import { motion } from 'framer-motion';
import styled from 'styled-components';

interface ToggleWrapperProps {
  color: string;
  onClick?: () => void;
}
interface ToggleDotProps {
  $color: string;
}

export const ToggleWrapper = styled.div<ToggleWrapperProps>`
  position: relative;
  border-radius: 12px;
  border: ${({ color }) => `1px solid ${color}`};
  width: 48px;
  height: 16px;
  padding: 3px;
  cursor: pointer;
`;

export const ToggleDot = styled(motion.div)<ToggleDotProps>`
  position: absolute;
  border-radius: 100%;
  background: ${({ $color }) => $color};
  width: 16px;
  height: 16px;
`;
