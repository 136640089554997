// import * as Sentry from '@sentry/react';
//import { AnimatePresence } from 'framer-motion';
import type { AppProps } from 'next/app';
import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';

import CookieBar from 'components/CookieBar/CookieBar';
import LinkedinInsightTag from 'components/LinkedinInsightTag';
import SlidingPageTransition from 'components/SlidingPageTransition';
// import NonFunctionals from 'u9/containers/NonFunctionals/NonFunctionals';
// import { ROUTES } from 'utils/routes';
// import customFonts from 'utils/styles/fonts';
import GlobalStyles from 'containers/GlobalStyle';
import PageTransition from 'containers/PageTransition';
import { setupStoreDevTools, useCopyStore } from 'store';
// import Head from 'u9/components/Head/Head';
// import LanguageSelector from 'u9/components/LanguageSelector/LanguageSelector';
import Version from 'u9/components/versioning/Version/Version';
import VersionScreen from 'u9/components/versioning/VersionScreen/VersionScreen';
import gtm from 'utils/gtm';
import { defaultTheme } from 'utils/theme';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'intersection-observer';

// if (process.env.ENV !== 'local') {
//   Sentry.init({
//     enabled: process.env.NODE_ENV !== 'development',
//     dsn: process.env.SENTRY_DSN,
//   });
// }

const App = ({ Component, pageProps, router }: AppProps) => {
  const [isMounted, setMounted] = useState(false);
  const { setCopy } = useCopyStore();

  useEffect(() => {
    gtm.initialize(true, false);
  }, []);

  useEffect(() => {
    if (process.env.IS_DEBUG) setupStoreDevTools();
    if (pageProps.initialCopy) setCopy(pageProps.initialCopy);

    setMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.StrictMode>
      <LinkedinInsightTag />
      <ThemeProvider theme={{ ...defaultTheme, locale: router.locale }}>
        <GlobalStyles />
        {/* <Head {...(pageProps.initialCopy || copy).head} /> */}
        {/* <LanguageSelector /> */}

        {/* @ts-ignore */}
        {/* <AnimatePresence mode="wait" initial={false}> */}
        <PageTransition router={router} component={SlidingPageTransition}>
          <Component key={router.route} router={router} {...pageProps} />
        </PageTransition>
        {/* </AnimatePresence> */}

        {process.env.IS_DEBUG && <Version />}
        {isMounted
          ? process.env.IS_DEBUG &&
            window.location.hash === '#version' && <VersionScreen />
          : null}
        <CookieBar />
      </ThemeProvider>
    </React.StrictMode>
  );
};

export default App;
