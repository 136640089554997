import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { media } from 'utils/detection';

export const BACKGROUND_HEIGHT = {
  xs: {
    closed: 161,
    open: 269.5,
  },
  s: {
    closed: 141.5,
    open: 269.5,
  },
  m: {
    closed: 82,
    open: 132,
  },
  l: {
    closed: 140,
    open: 268,
  },
  xxl: {
    closed: 82,
    open: 132,
  },
};

export const theme = {
  colors: {
    black: '#000',
    white: '#FFF',
    mercury: '#E5E5E5',
    silver: '#CCCCCC',
    gray: '#7F7F7F',
    wildSand: '#F6F6F6',
    mineShaft: '#262626',
    boulder: '#7A7A7A',
    darkerBoulder: '#767676',
    alto: '#D8D8D8',
    tundora: '#4A4A4A',
    alabaster: '#F7F7F7',
    darkerSilver: '#BABABA',
    silverChalice: '#B1B1B1',
    radicalRed: '#FF475D',
  },
  gap: '10px',
  mobileToggle: {
    height: '60px',
  },
  switchSize: {
    xs: 0,
    m: 38,
    l: 42,
    xl: 46,
    xxl: 62,
  },
};

interface CookieBarWrapperProps {
  $isHomePage: boolean;
}
interface CookieBarGridProps {
  $isSettingsExtended: boolean;
}
interface ButtonWrapperProps {
  onClick?: () => void;
}

const borderRadius = '10px';

export const TextWrapper = styled.div`
  display: grid;
  column-gap: 16px;
  grid-auto-flow: column;
  justify-content: space-between;
  height: min-content;
  transition: 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);

  & > div[data-type='fadeInOut'] {
    display: grid;
  }

  ${media.medium`
    align-items: center;
  `}
`;

export const Text = styled.span`
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.2px;
`;

export const CookieBarWrapper = styled(motion.div)<CookieBarWrapperProps>`
  position: fixed;
  display: grid;
  z-index: 99999;
  bottom: 16px;
  left: 16px;
  right: 16px;
  background: ${theme.colors.white};
  border-radius: ${borderRadius};
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.14);
  overflow: hidden;

  ${media.medium`
    left: ${({ theme, $isHomePage }) =>
      $isHomePage ? '16px' : `${theme.switchSize.m + 16}px`};
    right: ${({ theme, $isHomePage }) =>
      $isHomePage ? '16px' : `${theme.switchSize.m + 16}px`};
 `}

  ${media.mSmall`
    left: unset;
    right: ${({ theme, $isHomePage }) =>
      $isHomePage ? '16px' : `${theme.switchSize.l + 16}px`};
    width: 382px;
  `}

  ${media.mLarge`
    right: ${({ theme, $isHomePage }) =>
      $isHomePage ? '16px' : `${theme.switchSize.xl + 16}px`};
  `}

  ${media.large`
    right: ${({ theme, $isHomePage }) =>
      $isHomePage ? '16px' : `${theme.switchSize.xxl + 16}px`};
    min-width: 688px;
    width: ${({ theme, $isHomePage }) =>
      $isHomePage
        ? 'calc(50vw - 32px)'
        : `calc(50vw - ${theme.switchSize.xxl + 32}px)`};
  `}
`;

export const CookieBarGrid = styled(motion.div)<CookieBarGridProps>`
  display: grid;
  row-gap: 24px;
  padding: 24px;
  transition: height 0.6s cubic-bezier(0.25, 0.1, 0.25, 1);

  ${media.medium`
    align-items: center;
    column-gap: 24px;
    row-gap: 16px;
    padding: 16px 24px;

    ${({ $isSettingsExtended }) =>
      $isSettingsExtended
        ? css`
            padding: 32px 24px 16px;
            grid-template-columns: 1fr auto;

            ${TextWrapper} {
              grid-column: 1/-1;
            }
          `
        : css`
            grid-template-columns: 1fr auto;
          `}
  `}

  ${media.mSmall`
    padding: 24px;
    row-gap: 24px;
    grid-template-columns: unset;
  `}

  ${media.large`
    padding: 16px 24px;
    row-gap: 16px;

    ${({ $isSettingsExtended }) =>
      $isSettingsExtended
        ? css`
            padding: 32px 24px 16px;
            grid-template-columns: 1fr auto;

            ${TextWrapper} {
              grid-column: 1/-1;
            }
          `
        : css`
            grid-template-columns: 1fr auto;
          `}
  `}
`;

export const InfoWrapper = styled(motion.div)`
  line-height: 1;

  & small {
    display: inline-block;
    margin-right: 4px;
  }
`;

export const UnderlineText = styled(Text)`
  color: ${theme.colors.tundora};
  text-decoration: underline;
  cursor: pointer;
`;

export const ButtonWrapper = styled(motion.div)<ButtonWrapperProps>`
  width: 100%;
  height: min-content;
  background-color: ${theme.colors.white};
  border-radius: ${borderRadius};
  z-index: 1;

  button {
    height: 50px;
    line-height: 14px;
    letter-spacing: -0.1px;

    background: none;
    border: 1px solid grey;
    border-radius: 25px;
    padding: 16px 30px 16px 30px;
    width: 100%;
    height: min-content;
    text-align: center;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    min-width: 150px;
  }

  ${media.medium`
    width: min-content;
    right: 0;
    min-width: 150px;
    justify-self: end;
  `}

  ${media.mSmall`
    width: 100%;
  `}

  ${media.large`
    width: min-content;
    min-width: 150px;
  `}
`;

export const SettingsWrapper = styled(motion.div)`
  display: grid;
  row-gap: 16px;
  transform-origin: top center;

  ${media.medium`
    width: fit-content;
    grid-auto-flow: column;
    column-gap: 24px;
  `}

  ${media.mSmall`
    width: 100%;
    position: relative;
    grid-auto-flow: unset;
    bottom: unset;
    left: unset;
  `}

  ${media.large`
    width: fit-content;
    grid-auto-flow: column;
    column-gap: 24px;
  `}
`;

export const SettingRow = styled.div`
  color: ${theme.colors.tundora};
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;
  height: min-content;

  ${media.medium`
    width: fit-content;
    column-gap: 8px;
    padding: 0;
  `}

  ${media.mSmall`
    width: unset;
  `}

  ${media.large`
    width: fit-content;
    column-gap: 8px;
    padding: 0;
  `}
`;
