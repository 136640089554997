import React, { FC, useEffect, useState } from 'react';

import { theme } from 'components/CookieBar/CookieBar.styles';

import { ToggleVariants } from './Toggle.animations';

import * as S from './Toggle.styles';

export interface ToggleProps {
  initialState?: boolean;
  color?: string;
  disabled?: boolean;
  isActive?: boolean;
}

const Toggle: FC<ToggleProps> = ({
  initialState = false,
  color = theme.colors.black,
  disabled,
  isActive,
  ...rest
}) => {
  const [isToggled, setIsToggled] = useState(initialState);

  useEffect(() => {
    isActive !== undefined && setIsToggled(isActive);
  }, [isActive]);

  return (
    <S.ToggleWrapper
      color={color}
      onClick={() => !disabled && setIsToggled(prev => !prev)}
      {...rest}
    >
      <S.ToggleDot
        initial={initialState ? 'active' : 'inactive'}
        animate={isToggled ? 'active' : 'inActive'}
        variants={ToggleVariants.dot}
        $color={color}
      />
    </S.ToggleWrapper>
  );
};
export default Toggle;
