import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from './styled/Wrapper';
import ContentWrapper from './styled/ContentWrapper';
import Background from './styled/Background';
import Logo from './styled/Logo';
import LogoShort from './styled/LogoShort';

export default class SlidingPageTransition extends React.PureComponent {
  render() {
    const {
      className,
      isAnimationStarted,
      onExitFinish,
      shouldSkipEnterAnimation,
    } = this.props;

    return (
      <Wrapper
        className={className}
        isAnimationStarted={isAnimationStarted}
        onExitFinish={onExitFinish}
        shouldSkipEnterAnimation={shouldSkipEnterAnimation}
      >
        <ContentWrapper>
          <Background />
          <Logo />
          <LogoShort />
        </ContentWrapper>
      </Wrapper>
    );
  }
}
