interface CookieBarWrapperProps {
  height: number;
  x: number;
}

export const CookieBarVariants = {
  wrapper: ({ height, x }: CookieBarWrapperProps) => ({
    initial: {
      height: 0,
    },
    animate: {
      height,
      x,
      transition: {
        type: 'tween',
        duration: 0.4,
      },
    },
  }),
  settings: {
    initial: {
      opacity: 0,
      y: 60,
      transition: {
        duration: 0.6,
        ease: [0.25, 0.1, 0.25, 1],
      },
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        opacity: { duration: 0.4, delay: 0.4 },
        y: { duration: 0.5, delay: 0.2 },
        ease: [0.25, 0.1, 0.25, 1],
      },
    },
    exit: { opacity: 0 },
  },
};
