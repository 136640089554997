import styled from 'styled-components';

import { media } from 'utils/detection';

import logoShortUrl from './logo-short.svg';

export default styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${logoShortUrl});
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;

  ${media.medium`
    display: none;
  `}
`;
