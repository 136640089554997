import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import { isRouterReady } from 'utils/router';

import TransitionBackground from './styles/TransitionBackground';
export default class PageTransition extends React.PureComponent {
  static defaultProps = {
    duration: 1.6,
  };

  state = {
    isAnimationStarted: false,
  };

  componentDidMount() {
    const { router } = this.props;
    router.events.on('routeChangeStart', this.onRouteChangeStart);
  }

  componentWillUnmount() {
    const { router } = this.props;
    router.events.off('routeChangeStart', this.onRouteChangeStart);
  }

  onRouteChangeStart = () => {
    this.setState({ isAnimationStarted: true });
  };

  onExitFinish = () => {
    this.setState({ isAnimationStarted: false });
  };

  render() {
    const { className, children, component, duration, router } = this.props;
    const { isAnimationStarted } = this.state;

    const Component = component;

    const shouldSkipEnterAnimation = router.route === '/';

    return (
      <motion.div
        className={className}
        initial="exit"
        animate="enter"
        exit="exit"
        style={{ position: 'relative' }}
        variants={{
          initial: { opacity: 1, transition: { duration } },
          enter: { opacity: 1, transition: { duration } },
          exit: { opacity: 1, transition: { duration } },
        }}
      >
        {React.Children.toArray(children)}
        <Component
          onExitFinish={this.onExitFinish}
          isAnimationStarted={isAnimationStarted}
          shouldSkipEnterAnimation={shouldSkipEnterAnimation}
        />
        <TransitionBackground
          shouldSkipEnterAnimation={shouldSkipEnterAnimation}
          isAnimationStarted={isAnimationStarted}
        />
      </motion.div>
    );
  }
}
