import { css } from 'styled-components';

export const sizes = {
  xlarge: 1920,
  xxlarge: 2240,
  large: 1444,
  mLarge: 1280,
  mSmall: 1024,
  medium: 768,
  small: 320,
};

interface media {
  xlarge?: any;
  xxlarge?: any;
  medium?: any;
  large?: any;
  small?: any;
  mLarge?: any;
  mSmall?: any;
}

export const media: media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args: [any]) => css`
    @media (min-width: ${sizes[label]}px) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

export function isMedium() {
  return window.innerWidth >= sizes.medium;
}

export const isIE11 = () =>
  !!window.MSInputMethodContext && !!document.DOCUMENT_NODE;
