import styled from 'styled-components';
import { motion } from 'framer-motion';
export default styled(motion.div)`
  background-color: #101821;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: ${({ shouldSkipEnterAnimation }) =>
    shouldSkipEnterAnimation ? 0 : 1};
  top: 0;
  left: 0;
  display: ${({ isAnimationStarted }) =>
    isAnimationStarted ? 'block' : 'none'};
`;
