import { motion } from 'framer-motion';
import React, { FC } from 'react';

export interface FadeInOutProps {
  children: React.ReactNode;
  duration?: number;
  delay?: number;
  toggle?: boolean;
  shouldExit?: boolean;
  removeInitial?: boolean;
  style?: React.CSSProperties;
}

const FadeInOut: FC<FadeInOutProps> = ({
  children,
  shouldExit = true,
  removeInitial = false,
  delay = 0,
  toggle = true,
  ...rest
}) => {
  return (
    <motion.div
      data-type="fadeInOut"
      initial={{ opacity: removeInitial ? 1 : 0 }}
      animate={{ opacity: toggle ? 1 : 0, transition: { delay: delay } }}
      exit={{ opacity: shouldExit ? 0 : 1 }}
      {...rest}
    >
      {children}
    </motion.div>
  );
};
export default FadeInOut;
