import { createGlobalStyle } from 'styled-components';

import galanoClassicEot from 'fonts/GalanoClassic.eot';
import galanoClassicWoff from 'fonts/GalanoClassic.woff';
import galanoClassicTtf from 'fonts/GalanoClassic.ttf';
import galanoClassicSvg from 'fonts/GalanoClassic.svg';
import galanoClassicBoldEot from 'fonts/GalanoClassic-Bold.eot';
import galanoClassicBoldWoff from 'fonts/GalanoClassic-Bold.woff';
import galanoClassicBoldTtf from 'fonts/GalanoClassic-Bold.ttf';
import galanoClassicBoldSvg from 'fonts/GalanoClassic-Bold.svg';

export default createGlobalStyle`
  @font-face {
    font-family: 'Galano Classic';
    src: url(${galanoClassicEot});
    src: local('☺'), url(${galanoClassicWoff}) format('woff'), url(${galanoClassicTtf}) format('truetype'), url(${galanoClassicSvg}) format('svg');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Galano Classic Bold';
    src: url(${galanoClassicBoldEot});
    src: local('☺'), url(${galanoClassicBoldWoff}) format('woff'), url(${galanoClassicBoldTtf}) format('truetype'), url(${galanoClassicBoldSvg}) format('svg');
    font-weight: normal;
    font-style: normal;
  }

  html {
    font-size: 18px;
    overflow-x: hidden;
  }

  body {
    position: absolute;
    width: 100%;
    /* height: 100%; */
    margin: 0;
    overscroll-behavior: none;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
  }

  * {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  }
`;
