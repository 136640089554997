import { AnimatePresence } from 'framer-motion';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';

import FadeInOut from 'components/FadeInOut/FadeInOut';
import Toggle from 'components/Toggle/Toggle';
import { sizes } from 'utils/detection';

import { CookieBarVariants } from './CookieBar.animations';

import { BACKGROUND_HEIGHT, theme } from './CookieBar.styles';
import * as S from './CookieBar.styles';
const CookieBar = () => {
  const [isExtended, setIsExtended] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [functionality, setFunctionality] = useState(false);
  const [analytics, setAnalytics] = useState(false);

  const [deviceSize, setDeviceSize] = useState<'xs' | 's' | 'm' | 'l' | 'xxl'>(
    'xs'
  );

  const getDeviceSize = () => {
    window.innerWidth < sizes.small && setDeviceSize('xs');
    window.innerWidth >= sizes.small &&
      window.innerWidth < sizes.medium &&
      setDeviceSize('s');
    window.innerWidth >= sizes.medium &&
      window.innerWidth < sizes.mSmall &&
      setDeviceSize('m');
    window.innerWidth >= sizes.mSmall &&
      window.innerWidth < sizes.large &&
      setDeviceSize('l');
    window.innerWidth >= sizes.large && setDeviceSize('xxl');
  };

  const checkCookies = () => {
    const cookies = localStorage.getItem('lpb.cookiesConsent');
    cookies === 'true' ? setIsVisible(false) : setIsVisible(true);
  };

  const acceptCookies = () => {
    localStorage.setItem('lpb.cookiesConsent', 'true');
    localStorage.setItem('lpb.functionality', functionality.toString());
    localStorage.setItem('lpb.analytics', analytics.toString());
    setIsVisible(false);
  };

  useEffect(() => {
    getDeviceSize();
    window.addEventListener('resize', getDeviceSize);
    return () => {
      window.removeEventListener('resize', getDeviceSize);
    };
  });

  useEffect(checkCookies, []);

  const settingsComp = (
    <S.SettingsWrapper
      initial="initial"
      animate={isExtended ? 'visible' : 'initial'}
      exit="exit"
      variants={CookieBarVariants.settings}
    >
      <S.SettingRow>
        <S.Text>Strictly necessary</S.Text>
        <Toggle disabled color={theme.colors.alto} initialState />
      </S.SettingRow>

      <S.SettingRow onClick={() => setFunctionality(prev => !prev)}>
        <S.Text>Functionality</S.Text>
        <Toggle
          color={functionality ? theme.colors.black : theme.colors.tundora}
          isActive={functionality}
        />
      </S.SettingRow>

      <S.SettingRow onClick={() => setAnalytics(prev => !prev)}>
        <S.Text>Analytics</S.Text>

        <Toggle
          color={analytics ? theme.colors.black : theme.colors.tundora}
          isActive={analytics}
        />
      </S.SettingRow>
    </S.SettingsWrapper>
  );

  return (
    <AnimatePresence>
      {isVisible && (
        <FadeInOut>
          <S.CookieBarWrapper
            $isHomePage={true}
            variants={CookieBarVariants.wrapper({
              height: isExtended
                ? BACKGROUND_HEIGHT[deviceSize].open
                : BACKGROUND_HEIGHT[deviceSize].closed,
              x: 0,
            })}
            initial="initial"
            animate="animate"
          >
            <S.CookieBarGrid $isSettingsExtended={isExtended}>
              <S.TextWrapper>
                <S.InfoWrapper>
                  <S.Text>This website uses </S.Text>
                  <Link href={'/cookies'}>
                    <S.UnderlineText>cookies</S.UnderlineText>
                  </Link>
                </S.InfoWrapper>

                {!isExtended && (
                  <FadeInOut>
                    <S.UnderlineText
                      onClick={() => setIsExtended(prev => !prev)}
                    >
                      Manage Settings
                    </S.UnderlineText>
                  </FadeInOut>
                )}
              </S.TextWrapper>

              {isExtended && settingsComp}

              <S.ButtonWrapper>
                <button onClick={acceptCookies}>Accept</button>
              </S.ButtonWrapper>
            </S.CookieBarGrid>
          </S.CookieBarWrapper>
        </FadeInOut>
      )}
    </AnimatePresence>
  );
};

export default CookieBar;
