import React from 'react';

export const defaultTheme = {
  bg1: '#0c2234',
  bg2: '#101821',
  bgLight: '#ffffff',
  secondary: '#cdf4f2',
  textDark: '#101821',
  textSemiDark: '#112f47',
  textLight: '#cdf4f2',
  textBlue: '#0f416a',
  textLightGreen: '#a2e2df',
  fontGalanoClassic: 'Galano Classic',
  fontGalanoClassicSemiBold: 'Galano Classic - Semi Bold',
};

export const ThemeContext = React.createContext(defaultTheme);
