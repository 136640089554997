import styled, { css } from 'styled-components';

import { media } from 'utils/detection';
import Logo from 'components/Logo';

const StyledLogo = styled(Logo)`
  display: none;

  ${media.medium`
    display: block;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-61%, -50%) scale(1.25);
  `}
`;

StyledLogo.defaultProps = {
  color: '#101821',
};

export default StyledLogo;